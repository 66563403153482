/* eslint-disable import/no-named-as-default-member */
import axios from 'axios';
import qs from 'qs';
import mock, { getMockSync } from '@/item';

let usingMock = false;
const baseURL = process.env.VUE_APP_API_BASE || '/api';
const instance = axios.create({
  baseURL,
});
let accessToken = localStorage.getItem('token') || '';

function getHeader(config) {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (config?.isForm) {
    headers['Content-Type'] = 'multipart/form-data';
  }
  return headers;
}

export function setMock(value) {
  usingMock = value;
  console.log({ usingMock });
}

export async function login(username, password) {
  if (usingMock) {
    return mock.login(username, password);
  }
  const rsp = await instance.post('/login', qs.stringify({ username, password }));
  accessToken = rsp.data.access_token;
  return accessToken;
}

export async function uploadItem(file) {
  if (usingMock) {
    return mock.uploadItem(file);
  }
  const data = new FormData();
  data.append('file', file);
  await instance.post('/upload', data, { headers: getHeader({ isForm: true }) });
  return true;
}

export async function getList() {
  if (usingMock) {
    return mock.getList();
  }
  const rsp = await instance.get('/datas', { headers: getHeader() });
  return rsp.data;
}

export async function getItemDetail(id) {
  if (usingMock) {
    return mock.getItemStatus(id);
  }
  const rsp = await instance.get(`/data/${id}`, { headers: getHeader() });
  return rsp.data;
}

export async function getItemFailList(id) {
  if (usingMock) {
    return mock.getItemFailList(id);
  }
  const rsp = await instance.get(`/data/${id}/fail`, { headers: getHeader() });
  return rsp.data;
}

export async function getSyncList(year, month) {
  if (usingMock) {
    console.log({ usingMock }, 'getSyncList');
    return getMockSync();
  }
  const now = new Date();
  const rsp = await instance.get('/files', {
    params: {
      year: year || now.getFullYear(),
      month: month || now.getMonth() + 1,
    },
    headers: getHeader(),
  });
  return rsp.data.map((d) => ({
    ...d,
    sync_time: new Date(d.sync_time),
  }));
}

export async function retrySync(year, month, date) {
  const now = new Date();
  const rsp = await instance.post('/files/retry', {
    year: year || now.getFullYear(),
    month: month || now.getMonth() + 1,
    date: date || now.getDate(),
  }, {
    headers: getHeader(),
  });
  return {
    ...rsp.data,
    sync_time: new Date(rsp.data.sync_time),
  };
}
