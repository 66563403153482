import {
  ITEM_STATUS_PROCESSING,
  ITEM_STATUS_DONE,
} from '@/constant';

function waitMs(ms) {
  return new Promise((r) => {
    setTimeout(() => {
      r();
    }, ms);
  });
}

const itemManagement = {
  items: {},
  itemList: [],
  maxID: 0,
  async uploadFile(file) {
    this.maxID += 1;
    this.items[this.maxID] = {
      id: this.maxID,
      createTime: parseInt((new Date()).getTime() / 1000, 10),
      file,
      status: ITEM_STATUS_PROCESSING,
      filename: file.name,
      size: file.size,
      rows: 100 + parseInt(Math.random() * 200, 10),
    };
    this.itemList.push(this.items[this.maxID]);
    await waitMs(200);
    return new Promise((r) => {
      r(this.items[this.maxID]);
    });
  },
  async getItemStatus(id) {
    if (!this.items[id]) {
      return undefined;
    }
    this.items[id].status = ITEM_STATUS_DONE;
    await waitMs(200);
    return this.items[id]?.status;
  },
  async getItemFailList(id) {
    if (!this.items[id]) {
      return undefined;
    }
    await waitMs(200);
    return [];
  },
  async getItems() {
    await waitMs(200);
    return new Promise((r) => {
      r(this.itemList);
    });
  },
};

export async function login(username, password) {
  if (username !== 'aaa' || password !== 'bbb') {
    throw new Error('login fail');
  }
  return 'token';
}

export async function uploadItem(file) {
  const rsp = await itemManagement.uploadFile(file);
  return rsp;
}

export async function getList() {
  const rsp = await itemManagement.getItems();
  return JSON.parse(JSON.stringify(rsp));
}

export async function getItemStatus(id) {
  const rsp = await itemManagement.getItemStatus(id);
  return rsp;
}

export async function getItemFailList(id) {
  const rsp = await itemManagement.getItemFailList(id);
  return rsp;
}

export async function getMockSync() {
  return [
    {
      year: 2024,
      month: 10,
      date: 3,
      file_name: 'Invites_Taiwan_20241003.csv',
      sync_time: '2024-10-03T08:38:43.587401+00:00',
      status: 'success',
      error: '',
    },
    {
      year: 2024,
      month: 10,
      date: 4,
      file_name: 'Invites_Taiwan_20241003.csv',
      sync_time: '2024-10-04T09:00:03.386349+00:00',
      status: 'fail',
      error: 'FileNotFoundError',
    },
    {
      year: 2024,
      date: 5,
      month: 10,
      error: 'FileNotFoundError',
      file_name: 'Invites_Taiwan_20241005.csv',
      status: 'fail',
      sync_time: '2024-10-05T09:00:02.987409+00:00',
    },
    {
      year: 2024,
      date: 6,
      month: 10,
      error: 'FileNotFoundError',
      file_name: 'Invites_Taiwan_20241006.csv',
      status: 'fail',
      sync_time: '2024-10-06T09:00:02.866790+00:00',
    },
    {
      date: 7,
      month: 10,
      year: 2024,
      error: '',
      file_name: 'Invites_Taiwan_20241007.csv',
      status: 'success',
      sync_time: '2024-10-07T09:00:04.212419+00:00',
    },
    {
      date: 8,
      month: 10,
      year: 2024,
      error: 'FileNotFoundError',
      file_name: 'Invites_Taiwan_20241008.csv',
      status: 'fail',
      sync_time: '2024-10-08T11:14:58.910509+00:00',
    },
  ];
}
